import React from "react";
import { Link } from "react-router-dom";
import Logo from "../../../../assets/images/logo.svg"
import Texas from "../../../../assets/images/texas_footer.png"


const Footer = () => {
  return (
    <>
      <div className="footer">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 footer-text">
              <div className="footer-item">
                <div className="footer-logo">
                  <img src={Logo} className="logo" />
                  <span>© 2023 luci™</span>
                </div>
                <div className="footer-link">
                  <label>Product</label>
                  <ul>
                    {/* <li>
                      <a href="/#HowItWorks">
                        How it works
                      </a>
                    </li> */}
                    <li><a href="/support">Support</a></li>
                    {/*<li><a href="https://joinluci.com/" target="_blank">Credit Card Tool</a></li>*/}
                  </ul>
                </div>
                <div className="footer-link">
                  <label>Legal</label>
                  <ul>
                    <li><a href="/terms">Terms of Service</a></li>
                    <li><a href="/privacy-policy">Privacy Policy</a></li>
                    <li><a href="/cookies-policy">Cookies Policy</a></li>
                  </ul>
                </div>
                <div className="footer-link">
                  <label>Products by Us</label>
                  <ul>
                    <li><a href="https://joinluci.com" target="_blank">Luci Credit Card Tool</a></li>
                    <li><a href="/">Space Luci</a></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        <ul>
          <div className="footer-bottom-text" style={{ textAlign: "center" }}  >A giant leap for mankind, built in Space City Houston <img src={Texas} alt="" height={30} /> </div>
        </ul>
      </div>
    </>
  );
};

export default Footer;
