import Home from "../pages/Home";
import AboutUs from "../pages/AboutUs";
import Support from "../pages/Support";
import Terms from "../pages/Terms";
import PrivacyPolicy from "../pages/PrivacyPolicy";
import CookiesPolicy from "../pages/CookiesPolicy";

export const routePaths = {
  home: "/",
  about: "/about",
  support: "/support",
  terms: "/terms",
  privacy : "/privacy-policy",
  cookies : "/cookies-policy",
};

export const authRoutes = [
  {
    title: "Home",
    path: routePaths.home,
    url: routePaths.home,
    exact: true,
    main: Home
  },
];

export const unAuthRoutes = [
  {
    title: "Home",
    path: routePaths.home,
    url: routePaths.home,
    exact: true,
    main: Home
  },
  {
    title: "Support",
    path: routePaths.support,
    url: routePaths.support,
    exact: true,
    main: Support
  },
  {
    title: "About",
    path: routePaths.about,
    url: routePaths.about,
    exact: true,
    main: AboutUs
  },
  {
    title: "Terms",
    path: routePaths.terms,
    url: routePaths.terms,
    exact: true,
    main: Terms
  },
  {
    title: "Privacy",
    path: routePaths.privacy,
    url: routePaths.privacy,
    exact: true,
    main: PrivacyPolicy
  },
  {
    title: "Cookies",
    path: routePaths.cookies,
    url: routePaths.cookies,
    exact: true,
    main: CookiesPolicy
  },
];
