import React from "react";
import { Provider } from "react-redux";
import { ConnectedRouter } from "connected-react-router";
import { store, history } from "./common/services/ReduxService";
import AppLayout from "./common/components/AppLayout";
import Main from "./pages/Main";

const AppEntry = () => (
  <Provider store={store}>
    <ConnectedRouter history={history}>
        <AppLayout>
            <Main />
        </AppLayout>
    </ConnectedRouter>
  </Provider>
);

export default AppEntry;
