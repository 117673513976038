import React from "react";
import JoinMission from "../../common/components/JoinMission";
import Footer from "../../common/components/AppLayout/Footer";
import SpaceB from "../../assets/images/space-object-b-min.png"
import RocketCartoon from "../../assets/images/rocket-cartoon-min.png"
import SpaceD from "../../assets/images/space-object-d-min.png"
import Earth from "../../assets/images/app-screen/space_object_01.svg";
import EarthRound from "../../assets/images/app-screen/space_object_03.svg";
import EarthHalf from "../../assets/images/app-screen/space_object_03.svg";
import Eddie from "../../assets/images/avatar/eddie.png"
import Kanji from "../../assets/images/avatar/kanji.png"
import Luci from "../../assets/images/avatar/luci.png"
import Mike from "../../assets/images/avatar/mike.png"
import Richard from "../../assets/images/avatar/richard.png"
import Maulik from "../../assets/images/avatar/maulik-min.png"
import Parth from "../../assets/images/avatar/parth-min.png"
import Parang from "../../assets/images/avatar/parang-min.png"
import Rookie from "../../assets/images/avatar/rookie-min.png"
import Onyx from "../../assets/images/avatar/onyx-min.png"
import Peter from "../../assets/images/avatar/peter-min.png"
import Schwee from "../../assets/images/avatar/schwee.png"
import Product from "../../assets/images/product.jpg"
import Astroid from "../../assets/images/app-screen/space_object_03.svg";


class AboutUs extends React.Component {

    render() {

        return (
            <>
                <section className="banner b-inner about-banner">
                    <div className="shape4">
                        <img src={SpaceB} alt="space object b" height="" width="" />
                    </div>
                    <div className="shape7">
                        <img src={RocketCartoon} alt="rocket cartoon" height="" width="" />
                    </div>
                    <div className="shape6">
                        <img src={SpaceD} alt="space object d" height="" width="" />
                    </div>
                    <div className="align-center">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12 text-center z-11">
                                    <h1><span className="text-primary">Our </span> Mission</h1>
                                    <p className="w-60">Help our users win</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="app-screen f-app-screen">
                    <div className="custom-shape-divider-top custom-shape-divider-top-about">
                        <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120"
                            preserveAspectRatio="none">
                            <path
                                d="M0,0V7.23C0,65.52,268.63,112.77,600,112.77S1200,65.52,1200,7.23V0Z"
                                className="shape-fill"
                            />
                        </svg>
                    </div>
                    {/* <div className="earth-img-d-new">
                        <img src={EarthRound} alt="background earth" height="" width="" id="loading" />
                    </div> */}
                    <div className="support-bg">
                        <div className="earth-img-new astroid-b">
                            <img src={EarthHalf} alt="background earth" height="" width="" />
                        </div>
                        <div className="container">
                            {/* <div className="row">
                                <div className="col-md-12 text-center z-11">
                                    <h2 className="mb-0"><span className="text-primary">Let’s meet</span> Our expert
                                        team members</h2>
                                </div>
                            </div> */}
                            <div className="row about-padding">
                                <div className="col-12 col-lg-4 z-11">
                                    <div className="banner-text">
                                        <div className="text-center mb-3">
                                            <img src={Eddie} className="w-120 br-50" />
                                        </div>
                                        <h4 className="a-name">Eddie</h4>
                                        <div className="a-field">Engineering</div>
                                        <p className="a-txt">Previously worked at DRW as a trader. Built a poker AI bot
                                            and placed 2nd in MIT Pokerbots. MIT grad with a degree in Mathematics with
                                            Computer Science.<br /> <b>Fun Fact</b> Directed and produced a viral
                                            <a
                                                target="_blank"
                                                href="https://www.youtube.com/watch?v=lJtHNEDnrnY&amp;index=85&amp;list=FLs24Vw79d12S48aOcVMPwJw">YouTube
                                                video</a>&nbsp;(5.6M views) starring Richard as the MIT Psy.</p>
                                    </div>
                                </div>
                                {/* <div className="col-12 col-lg-4 z-11">
                                    <div className="banner-text">
                                        <div className="text-center mb-3">
                                            <img src={Kanji} className="w-120 br-50" /></div>
                                        <h4 className="a-name">Kanji Godhani</h4>
                                        <div className="a-field">Engineering</div>
                                        <p className="a-txt">Self taught coder who is a React, Node, .NET Core, and
                                            Javascript ninja. Enjoys building mobile app games for fun. Government
                                            Engineering College grad with a degree in Computer Science and
                                            Engineering.<br /><b>Fun Fact</b> Taught himself how to code at 12 and has
                                            been coding ever since.</p>
                                    </div>
                                </div> */}
                                <div className="col-12 col-lg-4 z-11">
                                    <div className="banner-text">
                                        <div className="text-center mb-3">
                                            <img src={Maulik} className="w-120 br-50" /></div>
                                        <h4 className="a-name">Maulik</h4>
                                        <div className="a-field">Engineering</div>
                                        <p className="a-txt">Self-taught coder who started coding in high school. 
                                        Built 10+ hit apps with a total of 100M+ downloads.<br /> <b>Fun Fact</b> Ranked top 10 in the world for most 
                                        chips won in Zynga Poker.</p>
                                    </div>
                                </div>
                                <div className="col-12 col-lg-4 z-11">
                                    <div className="banner-text">
                                        <div className="text-center mb-3">
                                            <img src={Parth} className="w-120 br-50" /></div>
                                        <h4 className="a-name">Parth</h4>
                                        <div className="a-field">Engineering</div>
                                        <p className="a-txt">Data scientist turned developer who is a foodie and a road tripper. 
                                        NHCE grad with a degree in Computer Engineering. <br /> <b>Fun Fact</b> Ranked top scores on skill-based games by 
                                        training AI to play for him.</p>
                                    </div>
                                </div>
                                <div className="col-12 col-lg-4 z-11">
                                    <div className="banner-text">
                                        <div className="text-center mb-3">
                                            <img src={Peter} className="w-120 br-50" /></div>
                                        <h4 className="a-name">Peter</h4>
                                        <div className="a-field">Design</div>
                                        <p className="a-txt">Artist at heart with 10+ years of experience with 12 successful app launches. 
                                        ADNU grad with a degree in Computer Animation.  <br /> <b>Fun Fact</b> Solo-developed an indie game with a thriving community.</p>
                                    </div>
                                </div>
                                <div className="col-12 col-lg-4 z-11">
                                    <div className="banner-text">
                                        <div className="text-center mb-3">
                                            <img src={Richard} className="w-120 br-50" /></div>
                                        <h4 className="a-name">Richard</h4>
                                        <div className="a-field">Product</div>
                                        <p className="a-txt">Previously worked at Honey as the 2nd product manager and
                                            Goldman Sachs as a quant trader. MIT grad with a degree in Mechanical
                                            Engineering.<br /> <b>Fun Fact</b> Scored a sack against 2013 NFL Draft’s 1st
                                            pick <a target="_blank"
                                                href="https://en.wikipedia.org/wiki/Eric_Fisher_(American_football)">Eric
                                                Fisher</a>&nbsp;- has a pic of it and will show it to anyone who
                                            asks and who doesn’t.</p>
                                    </div>
                                </div>
                                <div className="col-12 col-lg-4 z-11">
                                    <div className="banner-text">
                                        <div className="text-center mb-3">
                                            <img src={Schwee} className="w-120 br-50" /></div>
                                        <h4 className="a-name">Stephen</h4>
                                        <div className="a-field">Engineering</div>
                                        <p className="a-txt">Previously worked at DRW as a trader. Won first place in
                                            University of Chicago's Midwest Algorithmic Trading Competition. Caltech
                                            grad with a degree in Mechanical Engineering and a degree in Business,
                                            Economics, and Management.<br /> <b>Fun Fact</b> Cooked dinner for Stephen
                                            Hawking.</p>
                                    </div>
                                </div>

                                <div className="col-12 col-lg-4 z-11">
                                    <div className="banner-text">
                                        <div className="text-center mb-3">
                                            <img src={Onyx} className="w-120 br-50" /></div>
                                        <h4 className="a-name">Onyx</h4>
                                        <div className="a-field">Intern</div>
                                        <p className="a-txt">Loyal, cute, smart, and chubby toy pug with non-threatening bark. A meat lover, especially when it comes to chicken.<br /> 
                                        <b>Fun Fact</b>Cannot jump and knocks on door 4 or 5 times when he needs to go.</p>
                                    </div>
                                </div>

                                <div className="col-12 col-lg-4 z-11">
                                    <div className="banner-text">
                                        <div className="text-center mb-3">
                                            <img src={Parang} className="w-120 br-50" /></div>
                                        <h4 className="a-name">Parang</h4>
                                        <div className="a-field">Sidekick</div>
                                        <p className="a-txt">Our beloved sidekick. Short-haired tabby who loves sunbathing, sleeping on heated blankets, 
                                        and watching bird documentaries.  <br /> <b>Fun Fact</b>Born in 2002 and 100 y/o in human years!</p>
                                    </div>
                                </div>

                                <div className="col-12 col-lg-4 z-11">
                                    <div className="banner-text">
                                        <div className="text-center mb-3">
                                            <img src={Rookie} className="w-120 br-50" /></div>
                                        <h4 className="a-name">Rookie</h4>
                                        <div className="a-field">Intern</div>
                                        <p className="a-txt"> "If me fits, me sits."</p>
                                    </div>
                                </div>
                                {/*<div className="col-12 col-lg-4 z-11">
                                    <div className="banner-text">
                                        <div className="text-center mb-3">
                                            <img src={Mike} className="w-120 br-50" /></div>
                                        <h4 className="a-name">Mike Teodorescu</h4>
                                        <div className="a-field">Advisor</div>
                                        <p className="a-txt">Assistant Professor in the Information Systems Department
                                            at <a target="_blank"
                                                  href="https://www.bc.edu/bc-web/schools/carroll-school/faculty-research/faculty-directory/mike-teodorescu.html">Boston
                                                College</a>. Author of several patents in the technology fields and
                                            co-author of several software related patent applications. <br/><br/>Featured
                                            in McKinsey Analytics, IOT Solutions, and HBS Working Knowledge. PHD in
                                            Strategy and A.B. in Computer Science from Harvard.</p>
                                    </div>
                                </div>
                                <div className="col-12 col-lg-4 z-11">
                                    <div className="banner-text">
                                        <div className="text-center mb-3">
                                            <img src={Luci} className="w-120 br-50" /></div>
                                        <h4 className="a-name">Luci Duan</h4>
                                        <div className="a-field">Inspiration</div>
                                        <p className="a-txt">Personal finance wiz who always tells her friends about the
                                            best shopping deals, personal finance tips, and ways to maximize credit card
                                            rewards. Started investing her savings from her first job in a Roth IRA
                                            account at age 15. <br/><br/>This app was named after Luci with the goal
                                            that just as Luci helps her friends, luci will help users save money and
                                            time!</p>
                                    </div>
                                </div>*/}
                            </div>
                        </div>
                    </div>
                    <div className="custom-shape-divider-bottom">
                        <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120"
                            preserveAspectRatio="none">
                            <path d="M0,0V7.23C0,65.52,268.63,112.77,600,112.77S1200,65.52,1200,7.23V0Z"
                                className="shape-fill" />
                        </svg>
                    </div>
                </section>
                <section className="app-shot-one app-shot-one-f bg-btm-highlight" id="screens">
                    <div className="astroid-a">
                        <img src={Astroid} alt="background earth" height="" width="" />
                    </div>
                    <div className="align-center">
                        <div className="container our-product">
                            <div className="row">
                                <div className="col-md-12 text-center z-11">
                                    <h2>Other <span className="text-primary">Product</span> Built by Us</h2>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-12 beta-test-dot join-product mt-0 z-11">
                                    <h3>Maximize your credit card rewards</h3>
                                    <img src={Product} className="app-product" />
                                    <a className="btn lse" href="https://www.producthunt.com/products/luci-2#luci-2" target="_blank">
                                        Let’s Start Earning
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="position-relative align-center">
                        <div className="earth-img-new">
                            <img src={Earth} alt="background earth" height="" width="" />
                        </div>
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12 beta-test-dot mt-0">
                                    <JoinMission />
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </section>
            </>
        )
    }
}

export default AboutUs
