import { combineReducers } from "redux";
import { all } from "redux-saga/effects";
import { connectRouter } from "connected-react-router";
import homeSaga from "./home/homeSaga";
import { homeReducer } from "./home/homeReducer";

export const reducers = history =>
  combineReducers({
    router: connectRouter(history),
    homeReducer,
  });

export const rootSaga = function*() {
  yield all([
    homeSaga()
  ]);
};
