import React from "react";
import spaceObject01 from "../../../assets/images/home/space_object_01-min.png";
import spaceObject02 from "../../../assets/images/home/space_object_02-min.png";
import spaceObject03 from "../../../assets/images/home/space_object_03-min.png";
import spaceObject04 from "../../../assets/images/home/space_object_04-min.png";
import spaceObject05 from "../../../assets/images/home/space_object_05-min.png";
import spaceObject06 from "../../../assets/images/home/Astro_rideLean-min2.png";
import spaceObject07 from "../../../assets/images/home/space_object_07-min.png";
import spaceObject08 from "../../../assets/images/home/space_object_08-min.png";
import spaceObject09 from "../../../assets/images/home/space_object_09-min.png";
import spaceObject10 from "../../../assets/images/home/Doggy_fly-min.png";


const MissionMillionaire = () => {
    return (
        <section className="banner h-100vh">
            <div className="spaceObject01">
                <img src={spaceObject01} alt="spaceObject01" height="" width="" />
            </div>
            <div className="spaceObject02">
                <img src={spaceObject02} alt="spaceObject02" height="" width="" />
            </div>
            <div className="spaceObject03">
                <img src={spaceObject03} alt="spaceObject03" height="" width="" />
            </div>
            <div className="spaceObject04">
                <img src={spaceObject04} alt="spaceObject04" height="" width="" />
            </div>
            <div className="spaceObject05">
                <img src={spaceObject05} alt="spaceObject05" height="" width="" />
            </div>
            <div className="spaceObject07">
                <img src={spaceObject07} alt="spaceObject07" height="100%" width="100%" />
            </div>
            <div className="spaceObject08">
                <img src={spaceObject08} alt="spaceObject08" height="" width="" />
            </div>
            <div className="spaceObject09">
                <img src={spaceObject09} alt="spaceObject09" height="" width="" />
            </div>
            <div className="align-center banner-bg">
                <div className="container">
                    <div className="row g-9">
                        <div className="col-md-12 text-center z-11">
                            <img src={spaceObject10} alt="astronaut" className="doggy" width={300} />
                            <img src={spaceObject06} alt="astronaut" className="astronaut rocket-main" width={300} />
                            {/* Cruise through budgeting and saving with luci */}
                            <h1 className="cloud-title"><span className="text-secondary">The Game </span> to<span className="text-primary"></span> <br /> <span
                                className="text-secondary"></span><span className="text-primary">Financial Independence</span> <span
                                className="text-secondary"></span>
                            </h1>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default MissionMillionaire;
