import React from "react";
import ReactWOW from 'react-wow'
import { scrollToTop } from '../../utils/constants';
import Inner from "../../assets/images/arrow.svg";
import TitleLine from "../../assets/images/arrow.svg";
import SpaceB from "../../assets/images/space-object-b.svg";
import RocketCartoon from "../../assets/images/rocket-cartoon.svg";
import SpaceD from "../../assets/images/space-object-d.svg";
import Earth from "../../assets/images/bg-earth.svg";
import EarthRound from "../../assets/images/earth-round.svg";
import Arrow from "../../assets/images/arrow.svg"
import Footer from "../../common/components/AppLayout/Footer";
import JoinMission from "../../common/components/JoinMission";


const CookiesPolicy = () => {
    scrollToTop();
    return (
        <>
            <section className="banner b-inner">
                <div className="shape4">
                    <img src={SpaceB} alt="space object b" height="" width="" />
                </div>
                <div className="shape7">
                    <img src={RocketCartoon} alt="rocket cartoon" height="" width="" />
                </div>
                <div className="shape6">
                    <img src={SpaceD} alt="space object d" height="" width="" />
                </div>
                <div className="align-center">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 text-center z-11">
                                <h1>Cookies Policy</h1>
                                <p className="w-100">Last updated: May 28, 2020
                                    <br /><br />
                                    This Cookies Policy explains what Cookies are and how We use them. You should read
                                    this policy so You can understand what type of cookies We use, or the information We
                                    collect using Cookies and how that information is used.<br /><br />
                                    Cookies do not typically contain any information that personally identifies a user,
                                    but personal information that we store about You may be linked to the information
                                    stored in and obtained from Cookies. For further information on how We use, store and
                                    keep your personal data secure, see our Privacy Policy.<br /><br />
                                    We do not store sensitive personal information, such as mailing addresses, account
                                    passwords, etc. in the Cookies We use.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="informative-area app-screen f-app-screen">
                <div className="custom-shape-divider-top">
                    <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120"
                        preserveAspectRatio="none">
                        <path d="M0,0V7.23C0,65.52,268.63,112.77,600,112.77S1200,65.52,1200,7.23V0Z"
                            className="shape-fill" />
                    </svg>
                </div>
                <div className="inner-area">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 text-center z-11">
                                <h2 className="mb-0">Interpretation and Definitions</h2>
                            </div>
                        </div>

                        <div className="col-lg-12 mt-5 faq-area z-11">
                            <div className="info-text">
                                <div className="info-text">
                                    <h4>Interpretation</h4>
                                    <p>The words of which the initial letter is capitalized have meanings defined under the
                                        following conditions.<br />
                                        The following definitions shall have the same meaning regardless of whether they
                                        appear in singular or in plural.
                                    </p>
                                    <h4>Definitions</h4>
                                    <p>For the purposes of this Cookies Policy:</p>
                                    <div className="info-list">
                                        <ul>
                                            <li>
                                                <span>Company </span> (referred to as either "the Company", "We", "Us" or
                                                "Our" in this Cookies Policy) refers to Soon Science Inc., 8 The Green STE B
                                                Dover, DE 19901 .
                                            </li>
                                            <li><span>You </span> means the individual accessing or using the Website, or a
                                                company, or any legal entity on behalf of which such individual is accessing
                                                or using the Website, as applicable.
                                            </li>
                                            <li><span>Cookies </span> means small files that are placed on Your computer,
                                                mobile device or any other device by a website, containing details of your
                                                browsing history on that website among its many uses.
                                            </li>
                                            <li><span>Website </span> refers to luci Smart Finance, accessible from<a
                                                href="https://spaceluci.com/" rel="external nofollow noopener"
                                                target="_blank"> https://spaceluci.com/</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-12 text-center z-11">
                                <h2 className="mb-0">The use of the Cookies</h2>
                            </div>
                        </div>

                        <div className="col-lg-12 mt-5 faq-area z-11">
                            <div className="info-text">
                                <div className="info-text">
                                    <h2 className="h-2">Type of Cookies We Use</h2>
                                    <p>Cookies can be "Persistent" or "Session" Cookies. Persistent Cookies remain on your
                                        personal computer or mobile device when You go offline, while Session Cookies are
                                        deleted as soon as You close your web browser.
                                    </p>
                                    <p>We use both session and persistent Cookies for the purposes set out below:</p>
                                    <ul>
                                        <li>
                                            <p className="mt-4"><strong>Necessary / Essential Cookies</strong></p>
                                            <p>Type: Session Cookies</p>
                                            <p>Administered by: Us</p>
                                            <p>Purpose: These Cookies are essential to provide You with services available
                                                through the Website and to enable You to use some of its features. They help
                                                to authenticate users and prevent fraudulent use of user accounts. Without
                                                these Cookies, the services that You have asked for cannot be provided, and We
                                                only use these Cookies to provide You with those services.</p>
                                        </li>
                                        <li>
                                            <p className="mt-4"><strong>Functionality Cookies</strong></p>
                                            <p>Type: Persistent Cookies</p>
                                            <p>Administered by: Us</p>
                                            <p>Purpose: These Cookies allow us to remember choices You make when You use the
                                                Website, such as remembering your login details or language preference. The
                                                purpose of these Cookies is to provide You with a more personal experience and
                                                to avoid You having to re-enter your preferences every time You use the
                                                Website.</p>
                                        </li>
                                        <li>
                                            <p className="mt-4"><strong>Tracking and Performance Cookies</strong></p>
                                            <p>Type: Persistent Cookies</p>
                                            <p>Administered by: Third-Parties</p>
                                            <p>Purpose: These Cookies are used to track information about traffic to the
                                                Website and how users use the Website. The information gathered via these
                                                Cookies may directly or indirectly identify you as an individual visitor. This
                                                is because the information collected is typically linked to a pseudonymous
                                                identifier associated with the device you use to access the Website. We may
                                                also use these Cookies to test new advertisements, pages, features or new
                                                functionality of the Website to see how our users react to them.</p>
                                        </li>
                                        <li>
                                            <p className="mt-4"><strong>Targeting and Advertising Cookies</strong></p>
                                            <p>Type: Persistent Cookies</p>
                                            <p>Administered by: Third-Parties</p>
                                            <p>Purpose: These Cookies track your browsing habits to enable Us to show
                                                advertising which is more likely to be of interest to You. These Cookies use
                                                information about your browsing history to group You with other users who have
                                                similar interests. Based on that information, and with Our permission, third
                                                party advertisers can place Cookies to enable them to show adverts which We
                                                think will be relevant to your interests while You are on third party
                                                websites.</p>
                                        </li>
                                        <li>
                                            <p className="mt-4"><strong>Social Media Cookies</strong></p>
                                            <p>Type: Persistent Cookies</p>
                                            <p>Administered by: Third-Parties</p>
                                            <p>Purpose: In addition to Our own Cookies, We may also use various third parties
                                                Cookies to report usage statistics of the Website, deliver advertisements on
                                                and through the Website, and so on. These Cookies may be used when You share
                                                information using a social media networking websites such as Facebook,
                                                Instagram, Twitter or Google+.</p>
                                        </li>
                                    </ul>
                                    <h2 className="h-2 mt-4 pt-4">Your Choices Regarding Cookies</h2>
                                    <p>If You prefer to avoid the use of Cookies on the Website, first You must disable the
                                        use of Cookies in your browser and then delete the Cookies saved in your browser
                                        associated with this website. You may use this option for preventing the use of
                                        Cookies at any time.</p>
                                    <p>If You do not accept Our Cookies, You may experience some inconvenience in your use of
                                        the Website and some features may not function properly.</p>
                                    <p>If You'd like to delete Cookies or instruct your web browser to delete or refuse
                                        Cookies, please visit the help pages of your web browser.</p>
                                    <ul>
                                        <li>
                                            <p>For the Chrome web browser, please visit this page from Google:<a
                                                href="https://support.google.com/accounts/answer/32050"
                                                rel="external nofollow noopener"
                                                target="_blank"> https://support.google.com/accounts/answer/32050</a></p>
                                        </li>
                                        <li>
                                            <p>For the Internet Explorer web browser, please visit this page from Microsoft:<a
                                                href="http://support.microsoft.com/kb/278835" rel="external nofollow noopener"
                                                target="_blank"> http://support.microsoft.com/kb/278835</a></p>
                                        </li>
                                        <li>
                                            <p>For the Firefox web browser, please visit this page from Mozilla:<a
                                                href="https://support.mozilla.org/en-US/kb/delete-cookies-remove-info-websites-stored"
                                                rel="external nofollow noopener"
                                                target="_blank"> https://support.mozilla.org/en-US/kb/delete-cookies-remove-info-websites-stored</a>
                                            </p>
                                        </li>
                                        <li>
                                            <p>For the Safari web browser, please visit this page from Apple: <a
                                                href="https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/mac"
                                                rel="external nofollow noopener"
                                                target="_blank"> https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/mac</a>
                                            </p>
                                        </li>
                                    </ul>
                                    <p>For any other web browser, please visit your web browser's official web pages.</p>
                                    <h2 className="h-2 mt-4 pt-4">More Information about Cookies</h2>
                                    <p>You can learn more about cookies: <a href="https://www.termsfeed.com/blog/cookies/"
                                        target="_blank"> All About Cookies</a>.</p>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-12 text-center z-11">
                                <h2 className="mb-0">Contact Us</h2>
                            </div>
                        </div>

                        <div className="col-lg-12 mt-5 faq-area z-11">
                            <div className="info-text">
                                <div className="info-text">
                                    <p>If you have any questions about this Privacy Policy, You can contact us:</p>
                                    <ul>
                                        <li>By email: <a className="support--mail"
                                            href="mailto:support@joinluci.com">support@joinluci.com</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <div className="custom-shape-divider-bottom">
                    <svg
                        data-name="Layer 1"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 1200 120"
                        preserveAspectRatio="none"
                    >
                        <path
                            d="M0,0V7.23C0,65.52,268.63,112.77,600,112.77S1200,65.52,1200,7.23V0Z"
                            className="shape-fill"
                        />
                    </svg>
                </div>
            </section>
            <section className="app-shot-one app-shot-one-f" id="screens">
                <div className="align-center">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 beta-test-dot mt-0">
                                <JoinMission />
                            </div>
                        </div>
                    </div>
                </div>

                <Footer />
            </section>
        </>
    );
};

export default CookiesPolicy;
