import React from "react";
import JoinMission from "../../common/components/JoinMission";
import Footer from "../../common/components/AppLayout/Footer";
import { scrollToTop } from '../../utils/constants';
import SpaceB from "../../assets/images/space-object-b-min.png"
import SpaceD from "../../assets/images/space-object-d-min.png"
import RocketCartoon from "../../assets/images/rocket-cartoon-min.png"
//import Earth from "../../assets/images/bg-earth.svg"
import EarthRound from "../../assets/images/earth-round.svg"
import Arrow from "../../assets/images/arrow.svg"
//import Astroid from "../../assets/images/astroid.png"
import spaceObject01 from "../../assets/images/app-screen/space_object_01.svg";
//import spaceObject02 from "../../assets/images/app-screen/space_object_02.svg";
import spaceObject03 from "../../assets/images/app-screen/space_object_03.svg";


const Support = () => {
    scrollToTop();
    return (
        <>
            <section className="banner b-inner about-banner">
                <div className="shape4">
                    <img src={SpaceB} alt="space object b" height="" width="" />
                </div>
                <div className="shape7">
                    <img src={RocketCartoon} alt="rocket cartoon" height="" width="" />
                </div>
                <div className="shape6">
                    <img src={SpaceD} alt="space object d" height="" width="" />
                </div>
                <div className="align-center support-a">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 text-center z-11">
                                <h1 className="support-h1">Support <span className="text-primary">and</span> FAQ</h1>
                                <p className="w-60 pb-5">We always have your security in mind. Rest easy knowing that your data
                                    is protected with bank-level SSL encryption and 256-bit encryption.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="app-screen f-app-screen">
                <div className="custom-shape-divider-top ssb-light">
                    <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120"
                        preserveAspectRatio="none">
                        <path d="M0,0V7.23C0,65.52,268.63,112.77,600,112.77S1200,65.52,1200,7.23V0Z"
                            className="shape-fill sb-light" />
                    </svg>
                </div>
                {/* <div className="earth-img-d earth-img-d-new">
                    <img src={EarthRound} alt="background earth" height="" width="" id="loading" />
                </div> */}
                <div className="support-bg sb-light">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 text-center z-11">
                                <h2 className="mb-0">Frequently asked questions</h2>
                            </div>
                        </div>
                        <div className="col-lg-12 mt-5 faq-area z-11">
                            <div className="info-text">
                                <div className="accordion" id="accordionExample">
                                    <div className="card z-in9">
                                        <div className="card-header" id="headingOne">
                                            <button className="btn btn-link" type="button" data-toggle="collapse"
                                                data-target="#collapseOne" aria-expanded="true"
                                                aria-controls="collapseOne">
                                                What does Luci do?
                                                <img src={Arrow} className="arrow-toggle" />
                                            </button>
                                        </div>
                                        <div id="collapseOne" className="collapse show" aria-labelledby="headingOne">
                                            <div className="card-body">
                                            Luci is a game that helps you achieve financial independence. Users win in-game rewards and unlock game progress by accomplishing personal finance goals. 
                                            Users can also accomplish personal finance missions as a team and compete with others on financial awareness through personalized finance questions generated by Luci based on users' spending. 
                                            In addition, using patent-pending technology, Luci empowers users through powerful analytics tools that project the past, present, and future. 
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="card">
                                        <div className="card-header" id="headingTwo">
                                            <h4 className="mb-0">
                                                <button className="btn btn-link collapsed" type="button"
                                                    data-toggle="collapse" data-target="#collapseTwo"
                                                    aria-expanded="false" aria-controls="collapseTwo">
                                                    How does luci work?
                                                    <img src={Arrow} className="arrow-toggle" />
                                                </button>
                                            </h4>
                                        </div>
                                        <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo">
                                            <div className="card-body">
                                                Using the spending data that you share with luci, luci’s patent pending
                                                technology calculates what your rewards would have been if you had spent
                                                your money using different credit cards. <br /><br />
                                                luci calculates your reward potential for all the credit cards that are in
                                                luci’s database, and provides you with a personalized recommendation on
                                                cards that could give you the most reward for your spending habit.
                                            </div>
                                        </div>
                                    </div> */}
                                    <div className="card">
                                        <div className="card-header" id="headingThree">
                                            <h4 className="mb-0">
                                                <button className="btn btn-link collapsed" type="button"
                                                    data-toggle="collapse" data-target="#collapseThree"
                                                    aria-expanded="false" aria-controls="collapseThree">
                                                    How does Luci protect my personal information?
                                                    <img src={Arrow} className="arrow-toggle" />
                                                </button>
                                            </h4>
                                        </div>
                                        <div id="collapseThree" className="collapse" aria-labelledby="headingThree">
                                            <div className="card-body">
                                                Luci uses bank-level SSL encryption and secures your data with 256-bit
                                                encryption.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-header" id="heading-f">
                                            <h4 className="mb-0">
                                                <button className="btn btn-link collapsed" type="button"
                                                    data-toggle="collapse" data-target="#collapse-f"
                                                    aria-expanded="false" aria-controls="collapse-f">
                                                    Does Luci sell my information to third parties?
                                                    <img src={Arrow} className="arrow-toggle" />
                                                </button>
                                            </h4>
                                        </div>
                                        <div id="collapse-f" className="collapse" aria-labelledby="heading-f">
                                            <div className="card-body">
                                                Luci does not sell any of your personal information to third parties.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-header" id="heading-fv">
                                            <h4 className="mb-0">
                                                <button className="btn btn-link collapsed" type="button"
                                                    data-toggle="collapse" data-target="#collapse-fv"
                                                    aria-expanded="false" aria-controls="collapse-fv">
                                                    Is Luci free and how does Luci make money?
                                                    <img src={Arrow} className="arrow-toggle" />
                                                </button>
                                            </h4>
                                        </div>
                                        <div id="collapse-fv" className="collapse" aria-labelledby="heading-fv">
                                            <div className="card-body">
                                                Luci is 100% free to users. Luci makes money from users who watch optional
                                                video ads to support the team.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-header" id="heading-s">
                                            <h4 className="mb-0">
                                                <button className="btn btn-link collapsed" type="button"
                                                    data-toggle="collapse" data-target="#collapse-s"
                                                    aria-expanded="false" aria-controls="collapse-s">
                                                    Why do I need to connect to my bank accounts?
                                                    <img src={Arrow} className="arrow-toggle" />
                                                </button>
                                            </h4>
                                        </div>
                                        <div id="collapse-s" className="collapse" aria-labelledby="heading-s">
                                            <div className="card-body">
                                                By securely connecting to your bank accounts using Plaid, you can share your transaction data with Luci.
                                                Luci uses this data to create analytics, tracking, and goal-setting tools. Luci and Plaid cannot
                                                make transactions or transfers on your accounts.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-header" id="heading-sv">
                                            <h4 className="mb-0">
                                                <button className="btn btn-link collapsed" type="button"
                                                    data-toggle="collapse" data-target="#collapse-sv"
                                                    aria-expanded="false" aria-controls="collapse-sv">
                                                    What is Plaid and is it secure?
                                                    <img src={Arrow} className="arrow-toggle" />
                                                </button>
                                            </h4>
                                        </div>
                                        <div id="collapse-sv" className="collapse" aria-labelledby="heading-sv">
                                            <div className="card-body">
                                                <a href="https://plaid.com/security/" target="_blank">Plaid</a> helps you securely connect apps and services with your bank accounts.
                                                Plaid uses a combination of Advanced Encryption Standard (AES-256) and Transport Layer Security (TLS) to help keep your personal information
                                                safe end-to-end. The end-to-end encryption ensures that Luci does not receive or see your bank account login credentials.
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="card">
                                        <div className="card-header" id="heading-e">
                                            <h4 className="mb-0">
                                                <button className="btn btn-link collapsed" type="button"
                                                    data-toggle="collapse" data-target="#collapse-e"
                                                    aria-expanded="false" aria-controls="collapse-e">
                                                    How do I get started?
                                                    <img src={Arrow} className="arrow-toggle" />
                                                </button>
                                            </h4>
                                        </div>
                                        <div id="collapse-e" className="collapse" aria-labelledby="heading-e">
                                            <div className="card-body">
                                                Simply make an account by clicking <a href="signup.html"
                                                    target="_blank">here</a>. We are
                                                excited to help you save money and time!
                                            </div>
                                        </div>
                                    </div> */}
                                    <div className="card">
                                        <div className="card-header" id="heading-v">
                                            <h4 className="mb-0">
                                                <button className="btn btn-link collapsed" type="button"
                                                    data-toggle="collapse" data-target="#collapse-v"
                                                    aria-expanded="false" aria-controls="collapse-v">
                                                    How can I get in touch?
                                                    <img src={Arrow} className="arrow-toggle" />
                                                </button>
                                            </h4>
                                        </div>
                                        <div id="collapse-v" className="collapse" aria-labelledby="heading-v">
                                            <div className="card-body">
                                                We love hearing from our users. Email us at <a
                                                    href="mailto:support@joinluci.com">support@joinluci.com</a>.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="custom-shape-divider-bottom ssb-light">
                    <svg
                        data-name="Layer 1"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 1200 120"
                        preserveAspectRatio="none"
                    >
                        <path
                            d="M0,0V7.23C0,65.52,268.63,112.77,600,112.77S1200,65.52,1200,7.23V0Z"
                            className="shape-fill"
                        />
                    </svg>
                </div>
            </section>
            <section className="app-shot-one app-shot-one-f bg-btm-highlight" id="screens">
                <div className="astroid-a">
                    <img src={spaceObject03} alt="background earth" height="" width="" />
                </div>
                <div className="position-relative align-center">
                    <div className="earth-img-new earth-support">
                        <img src={spaceObject01} alt="background earth" height="" width="" />
                    </div>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 beta-test-dot mt-support">
                                <JoinMission />
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </section>
        </>
    );
};

export default Support;
