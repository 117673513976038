import { call, takeLatest } from "redux-saga/effects";
import { request } from "../../../utils/fetch";
import { HomeConstants } from "./homeConstants";

function* getUserDetails(action) {
  yield call(
    request({
      type: HomeConstants.FETCH_USER_DETAILS,
      method: "GET",
      url: `user/details`,
      success: action.payload ? action.payload.onSuccess : null,
      fail: action.payload? action.payload.onFail : null
    }),
    action
  );
}

export default function* rootSaga() {
  yield takeLatest(HomeConstants.FETCH_USER_DETAILS, getUserDetails);
}
