import React, { useState } from "react";
// import joinImage from "../../../assets/images/join/space_object_01.svg";
import AstroSquad from "../../../assets/images/AstroSquad-min2.png";
import AppleButton from "../../../assets/images/Download_on_the_Appstore.svg";
import { useLocation } from 'react-router-dom';
import Axios from "axios";
import { config } from '../../../utils/config';



const JoinMission = ({ background, isPadding }) => {
    const location = useLocation();
    const [emailData, setEmailData] = useState({
        email: "",
        code: ""
    });
    const [error, setError] = useState();
    const [success, setSuccess] = useState("");

    const handleChange = (event) => {
        let { name, value } = event.target;
        setEmailData({ ...emailData, [name]: value });
        setError("");
    }
    const handleSubmit = async () => {
        const emailRegx = RegExp("([!#-'*+/-9=?A-Z^-~-]+(\.[!#-'*+/-9=?A-Z^-~-]+)*|\"\(\[\]!#-[^-~ \t]|(\\[\t -~]))+\")@([!#-'*+/-9=?A-Z^-~-]+(\.[!#-'*+/-9=?A-Z^-~-]+)*|\[[\t -Z^-~]*])");
        if (emailData.email == "") {
            return setError("Please enter email")
        } else if (!emailRegx.test(emailData.email)) {
            return setError("Please enter a valid email")
        }
        const data = await Axios.post(`${config.API_URL}/api/subscribe`, { email: emailData.email, code: emailData.code });
        if (data?.status == 200) {
            setSuccess("Success")
            setTimeout(() => {
                setSuccess("")
            }, 2000);
            return setEmailData({ email: "", code: "" });
        } else {
            setError(data?.data?.error);
        }
    };
    return (
        <>
            <section className={`${location.pathname === '/' && 'join-container home-page'
                }`}>
                <div className={`join-padding ${background ? 'join-background' : '' || isPadding ? 'join-padding-new' : ''}`}>
                    {
                        background ? <div className="join-image-box">
                            <img src={AstroSquad} alt="astronaut" className="join-image" />
                        </div> : <></>
                    }
                    <div className="join-text">
                        <h2>Join the <span className="text-primary">10,000+ People</span> ready for takeoff</h2>
                        {/* <div className="email-form">
                            <div className="email-input">
                                <input type="email" placeholder="Your Email" name="email" className="input-email" value={emailData.email} onChange={handleChange} />
                                <input type="email" placeholder="Code (optional)" name="code" className="input-email code-optional" value={emailData.code} onChange={handleChange} />
                                <div className={`${success ? 'success' : "validation"}`}>{success ? success : error}</div>
                            </div>
                        </div>
                        <a className="btn luci-btn-dark" onClick={() => handleSubmit()}>Get Early Access</a> */}
                        <a href="https://apps.apple.com/us/app/luci-soon-science-inc/id1608114955"> <img src={AppleButton} alt="applebutton" className="join-image" /></a>
                    </div>
                </div>
            </section>
        </>
    );
};

export default JoinMission;
