import React from "react";
import { Route, Switch } from "react-router-dom";
import {
  authRoutes,
  unAuthRoutes
} from "../routes";

const token = localStorage.getItem("AuthToken");

const LoggedInRoutes = user =>
  authRoutes.map((r, i) => (
    <Route
      key={i}
      path={r.path}
      exact={r.exact}
      render={props => <r.main {...props} user={user} />}
    />
  ));

const LoggedOutRoutes = unAuthRoutes.map((r, i) => (
  <Route
    key={i}
    path={r.path}
    exact={r.exact}
    render={props => <r.main {...props} />}
  />
));

const Main = () => {
  return (
    <Switch>{[token ? LoggedInRoutes({}) : LoggedOutRoutes]}</Switch>
  );
};

export default Main;
