import React from "react";
import MissionMillionaire from "../../common/components/MissionMillionaire";
import PersonalFinance from "../../common/components/PersonalFinance";
import AppVisuals from "../../common/components/AppVisuals";
import JoinMission from "../../common/components/JoinMission"
import Footer from "../../common/components/AppLayout/Footer";
import spaceObject01 from "../../assets/images/app-screen/space_object_01.svg";
import spaceObject02 from "../../assets/images/app-screen/space_object_02.svg";
import spaceObject03 from "../../assets/images/app-screen/space_object_03.svg";

const Home = () => {
    return (
        <div>
            <MissionMillionaire />
            <JoinMission isPadding={true} />
            {/* <section className="app-screen">
                <div className="apSpaceObject01">
                    <img src={spaceObject01} alt="apSpaceObject01" height="" width="" />
                </div>
                <div className="apSpaceObject02">
                    <img src={spaceObject02} alt="apSpaceObject03" height="" width="" />
                </div>
                <div className="apSpaceObject03">
                    <img src={spaceObject03} alt="apSpaceObject03" height="" width="" id="loading" />
                </div>
                <PersonalFinance />
            </section> */}
            <section className="app-shot-one app-shot-one-f bg-btm-highlight" id="screens">
                {/* <JoinMission background={true} /> */}
                {/* <section className="app-shot-one" id="screens">
                    <AppVisuals />
                </section> */}
                <Footer />
            </section>
        </div>
    );
};

export default Home;
