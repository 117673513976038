import React, { useEffect, useState } from "react";
import { Link, withRouter } from "react-router-dom"
import Logo from "../../../../assets/images/logo.svg";
import NavMenuIcon from "../../../../assets/images/navMenu.svg";

const routes = {
  HOME: '/',
  SUPPORT: '/support',
  ABOUT: '/about',
}

const Header = () => {
  const [path, setPath] = useState('')

  useEffect(() => {
    setPath(window.location.pathname)
  }, [window.location.pathname])

  return (
    <header className="">
      <div className="container">
        <div className="row">
          <div className="col">
            <nav className="navbar navbar-expand-lg navbar-light">
              <Link to="/" className="logo">
                <img src={Logo} height="" width="" />
              </Link>
              <button
                className="navbar-toggler"
                role="button"
                id="navbarTogglerDemo02"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                {/*<span className="navbar-toggler-icon"/>*/}
                <img src={NavMenuIcon} width={`25px`} height={`25px`} />
              </button>
              <div className={`collapse navbar-collapse  logout-area dropdown-menu`} aria-labelledby="navbarTogglerDemo02">
                <ul className="navbar-nav ml-auto mt-2 mt-lg-0">
                  <li className={`nav-item ${path === routes.HOME ? "active" : ""}`}>
                    <Link to={routes.HOME} className="nav-link">Home</Link>
                  </li>
                  <li className={`nav-item ${path === routes.SUPPORT ? "active" : ""}`}>
                    <Link to={routes.SUPPORT} className="nav-link">Support</Link>
                  </li>
                  <li className={`nav-item ${path === routes.ABOUT ? "active" : ""}`}>
                    <Link to={routes.ABOUT} className="nav-link">About Us</Link>
                  </li>
                </ul>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </header>
  );
};

export default withRouter(Header);
